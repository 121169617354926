import Image from 'next/image';
import Link from 'next/link';
import { FaFacebookF, FaInstagram, FaLinkedinIn } from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';

export default function Footer() {
  return (
    <>
      <div className="w-full sm:min-h-[356px] xs:min-h-auto bg-[#2C3B53] flex flex-col-reverse sm:flex-row sm:pt-10 xs:px-8 xs:py-5">
        <div className="flex basis-1/3  sm:h-full xs:h-aut0 flex-col justify-start items-start xs:mt-9 sm:mt-0 space-y-4">
          <Image
            src="/images/ic-logo-light.png"
            height={76}
            width={171}
            style={{ minWidth: '171px' }}
            alt="Interview Cube Logo"
          />
          <div className="w-[285px] text-zinc-400 xs:text-sm sm:text-base font-medium ">
            Real People, Authentic Discussions.
            <br />
            Transforming Hiring Together.
          </div>
          <div className="text-zinc-500 xs:text-xl sm:text-2xl font-medium ">
            Follow us
          </div>
          <div className="flex justify-between align-center text-zinc-500 gap-4">
            <Link
              href="https://www.instagram.com/interviewcube_space/"
              target="_blank"
            >
              <FaInstagram size={28} />
            </Link>
            <Link
              href="https://www.linkedin.com/company/interviewcube/"
              target="_blank"
            >
              <FaLinkedinIn size={28} />
            </Link>
            <Link
              href="https://www.facebook.com/profile.php?id=61566093598719"
              target="_blank"
            >
              <FaFacebookF size={28} />
            </Link>
            <Link href="https://x.com/interviewcube" target="_blank">
              <FaXTwitter size={28} />
            </Link>
          </div>
        </div>
        <div className="flex sm:flex-row xs:flex-col w-full basis-2/3 sm:mt-10 xs:mt-0">
          <div className="flex basis-4/6 h-full flex-wrap items-start justify-start">
            <div className="xs:basis-1/2 sm:basis-1/3 space-y-3 flex flex-col">
              <p className="text-white xs:text-lg sm:text-xl xs:font-medium sm:font-semibold ">
                Company
              </p>
              <Link
                href="/about-us"
                className="text-neutral-400 xs:text-sm sm:text-base font-normal "
              >
                About Us
              </Link>
              <Link
                href="/blogs"
                className="text-neutral-400 xs:text-sm sm:text-base font-normal"
              >
                Blogs
              </Link>
              <Link
                href="/faq"
                className="text-neutral-400 xs:text-sm sm:text-base font-normal "
              >
                FAQs
              </Link>
              <Link
                href="/contact-us"
                className="text-neutral-400 xs:text-sm sm:text-base font-normal "
              >
                Contact Us
              </Link>
            </div>
            <div className="xs:basis-1/2 sm:basis-1/3 space-y-3">
              <div className="text-white xs:text-lg sm:text-xl xs:font-medium sm:font-semibold ">
                Features
              </div>
              <div className="text-neutral-400 xs:text-sm sm:text-base font-normal ">
                <Link href="../features#feedback">Feedback Collaborator</Link>
              </div>
              <div className="text-neutral-400 xs:text-sm sm:text-base font-normal ">
                <Link href="../features#interviewslot">
                  Interview Slot Assistant
                </Link>
              </div>
              <div className="text-neutral-400 xs:text-sm sm:text-base font-normal ">
                <Link href="../features#prescreen">Prescreen Profiler</Link>
              </div>
              <div className="text-neutral-400 xs:text-sm sm:text-base font-normal ">
                <Link href="../features#integratedvideo">
                  Integrated Video Platform
                </Link>
              </div>
            </div>
            <div className="sm:basis-1/3  flex flex-col justify-start space-y-3">
              <div className="text-[#2C3B53] text-xl font-semibold  ">.</div>
              <Link href="/cube" className="text-white text-base font-normal ">
                Cube
              </Link>
              <Link
                href="/journey"
                className="text-white text-base xs:font-medium sm:font-normal "
              >
                Journey
              </Link>
              <Link
                href="/pricing"
                className="text-white text-base xs:font-medium sm:font-normal "
              >
                Pricing
              </Link>
              <Link
                href="/remote-interview"
                className="text-white text-base xs:font-medium sm:font-normal "
              >
                Join Interview
              </Link>
              <Link
                href="/contact-us"
                className="text-white text-base xs:font-medium sm:font-normal "
              >
                Be a Host
              </Link>
            </div>
          </div>
          <div className="flex basis-2/6 h-full flex-col justify-start space-y-3 xs:mt-9 sm:mt-0">
            <div className="text-white xs:text-lg sm:text-xl xs:font-medium sm:font-normal ">
              Where to meet us?
            </div>
            <div className="text-neutral-400 xs:text-sm sm:text-base font-normal ">
              Tower of Hills
            </div>
            <div className="text-neutral-400 xs:text-sm sm:text-base font-normal ">
              13809 Research Blvd, Suite 735 Austin, TX 78641
            </div>
            <div className="text-neutral-400 xs:text-sm sm:text-base font-normal ">
              Call: +1 5163607482
            </div>
            <div className="text-neutral-400 xs:text-sm sm:text-base font-normal flex flex-start ">
              Email ID:
              <a href="mailto:contact@interviewcube.com" className="ml-1">
                contact@interviewcube.com
              </a>
            </div>
            <Link
              href="/contact-us"
              className="text-center text-white text-sm font-medium bg-blue-700 rounded-lg lg:rounded-full py-3 px-4 w-fit"
            >
              Reach Our Sales Team Now!
            </Link>
          </div>
        </div>
      </div>
      <div className="w-full h-full text-center px-6 sm:pb-5 xs:pb-2 xs:break-words bg-[#2C3B53]">
        <Link
          href="/terms-privacy/terms-conditions"
          className="text-indigo-400 xs:text-sm sm:text-base font-normal mr-2"
        >
          Terms & Conditions
        </Link>
        <Link
          href="/terms-privacy/privacy-policy"
          className="text-indigo-400 xs:text-sm sm:text-base font-normal"
        >
          Privacy Policy
        </Link>
        <span className="text-white mx-2">|</span>
        <Link
          href="/terms-privacy/privacy-policy"
          className="text-white xs:text-sm sm:text-base font-normal"
        >
          Copyright, Trademark, Patent Policy,
        </Link>
        <Link
          href="/terms-privacy/disclosure-policy"
          className="text-white xs:text-sm sm:text-base font-normal"
        >
          {' '}
          Disclosure Policy
        </Link>
      </div>
    </>
  );
}
